<template>
  <div class="item_board">
    <div class="area_img" :style="{ 'background-image' : `url(${itemData.thumbnail})`}"></div>
    <div class="area_content">
      <p class="desc_content">{{ itemData.title }}</p>
      <div class="group_btn">
        <button
          id="btnShare"
          class="btn_share"
          @click="onClickShare">
          <IconSvg
            iconName="preview_share"
            iconColor="#BEA9ED"
            bgcolor="#F3F1F1"
            :size="24"/>
          <span class="screen_out">공유하기</span>
        </button>
        <button class="btn_detail" @click="onClickDetail">
          읽어보기
          <IconSvg
            iconName="preview_arrow_right"
            iconColor="#8F69DD"
            :size="24"/>
        </button>
      </div>
    </div>
    <div
      v-if="isShareOpen"
      id="dropBoxShare"
      class="drop_box_share">
      <button
        class="btn_share btn_share_kakaotalk"
        @click="onClickShareSns('kakaotalk')">
        <IconSvg
          iconName="preview_kakaotalk"
          iconColor="#BEA9ED"
          bgcolor="#F3F1F1"
          :size="36"/>
        <span class="screen_out">kakaotalk 공유하기</span>
      </button>
      <button
        class="btn_share btn_share_facebook"
        @click="onClickShareSns('facebook')">
        <IconSvg
          iconName="preview_facebook"
          iconColor="#BEA9ED"
          bgcolor="#F3F1F1"
          :size="36"/>
        <span class="screen_out">facebook 공유하기</span>
      </button>
      <button
        class="btn_share btn_share_twitter"
        @click="onClickShareSns('twitter')">
        <IconSvg
          iconName="preview_twitter"
          iconColor="#BEA9ED"
          bgcolor="#F3F1F1"
          :size="36"/>
        <span class="screen_out">twitter 공유하기</span>
      </button>
      <button
        class="btn_share btn_share_link"
        @click="onClickShareSns('link')">
        <IconSvg
          iconName="preview_link"
          iconColor="#BEA9ED"
          bgcolor="#F3F1F1"
          :size="36"/>
        <span class="screen_out">URL 공유하기</span>
      </button>
    </div>
  </div>
</template>

<script>
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
export default {
  name:'BoardItem',
  props:{
    itemData: Object,
    itemRouteName: String,
  },
  components:{
    IconSvg,
  },
  data(){
    return {
      isShareOpen : false
    }
  },
  methods:{
    onClickDetail(e){
      // if( this.itemRouteName ){
      //   let routeInfo = { name: this.itemRouteName };
      //   if(this.itemData.link || this.itemData.id){
      //     routeInfo.params = { id: this.itemData.link || this.itemData.id};
      //   }
      //   this.$router.push(routeInfo);
      //   return;
      // }
      // this.$emit('onClickBtn',e);
    },
    onClickShare(){
      // console.log('onClickShare',this.isShareOpen)
      // window.addEventListener('touchstart',this.onBlurShare,true)
      // this.isShareOpen = !this.isShareOpen;
    },
    onBlurShare(e){
      // console.log('onBlurShare',this.isShareOpen)
      if(e.path.findIndex(item => item.id === 'btnShare') > -1 || e.path.findIndex(item => item.id === 'dropBoxShare') > -1) return;
      // console.log('onBlurShare2',this.isShareOpen)
      window.removeEventListener('touchstart', this.onBlurShare,true);
      this.closeShare()
    },
    closeShare(){
      this.isShareOpen = false;
    },
    onClickShareSns(type){
      this.$store.dispatch('commonToast/fetchToastStart', {
        toastType : 'success',
        toastMsg: `${type}로 공유되었습니다`
      });
    }
  }
}
</script>
<style scoped>
.item_board{display:inline-block;position:relative;width:calc(33.33% - 26.7px);max-width:400px;box-shadow:8px 8px 32px rgba(0,0,0,0.04);vertical-align:top;text-align:left}
.item_board:nth-child(3n+2){margin-left:40px}
.item_board:nth-child(3n+3){margin-left:40px}
.item_board:nth-child(n+4){margin-top:48px}
.item_board .area_img{padding-top:75%;border-radius:4px 4px 0 0;background-position:center;background-size:auto 100%;background-repeat:no-repeat;background-color:#DDD2BD}

.area_content{margin-top:-4px;padding:24px;background-color:#fff}
.area_content .desc_content{display:block;display:-webkit-box;overflow:hidden;height:72px;font-weight:700;font-size:24px;line-height:36px;word-wrap:break-word;word-break:keep-all;-webkit-line-clamp:2;-webkit-box-orient:vertical;}


.group_btn{overflow:hidden;margin-top:16px}
.group_btn .btn_share{float:left}
.group_btn .btn_share .icon_preview_share{padding:8px;border-radius:100%}
.group_btn .btn_detail{float:right;font-weight:600;font-size:18px;line-height:40px;color:#8F69DD}
.group_btn .btn_detail .icon_preview_arrow_right{margin:8px 0 8px 4px}

.drop_box_share{position:absolute;left:0;bottom:-112px;z-index:10;padding:32px 20px;border-radius:4px;background-color:#fff;box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.24);}
.drop_box_share .btn_share .icon_firfin{border-radius:100%}
.drop_box_share .btn_share + .btn_share{margin-left:24px}
</style>