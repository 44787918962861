<template>
    <div class="view_comm">
      <div class="head_view">
        <div v-if="isThumbnail" class="area_img" :style="{ 'background-image' : `url(${detailData.thumbnail})`}"></div>
        <div
          v-if="isShare"
          class="group_share">
            <button class="btn_share btn_share_kakaotalk">
              <IconSvg
                iconName="preview_kakaotalk"
                iconColor="#BEA9ED"
                bgcolor="#F3F1F1"
                :size="36"/>
            </button>
            <button class="btn_share btn_share_facebook">
              <IconSvg
                iconName="preview_facebook"
                iconColor="#BEA9ED"
                bgcolor="#F3F1F1"
                :size="36"/>
            </button>
            <button class="btn_share btn_share_twitter">
              <IconSvg
                iconName="preview_twitter"
                iconColor="#BEA9ED"
                bgcolor="#F3F1F1"
                :size="36"/>
            </button>
            <button class="btn_share btn_share_link">
              <IconSvg
                iconName="preview_link"
                iconColor="#BEA9ED"
                bgcolor="#F3F1F1"
                :size="36"/>
            </button>
        </div>
        <strong class="tit_view">{{ detailData.title }}</strong>
        <span class="date_view" v-if="isDate">{{ detailData.createdAt | convertDateFormat('YYYY.MM.DD') }}</span>
      </div>
      <div class="body_view">
        <div class="editor_view" v-html="detailData.contents"></div>
      </div>
      <div class="foot_view" v-if="isFooterBtnControaler || isShare">
        <div
          v-if="isFooterBtnControaler"
          class="inner_foot_btn">
          <div class="area_left">
            <button class="btn_view_foot btn_view_prev">
              <IconSvg
                iconName="preview_arrow_left"
                iconColor="#232323"
                :size="16"/>
              이전글
            </button>
          </div>
          <div class="area_right">
            <button class="btn_view_foot btn_view_next">
              다음글
              <IconSvg
                iconName="preview_arrow_right"
                iconColor="#232323"
                :size="16"/>
            </button>
          </div>
        </div>
        <div
          v-if="isShare"
          class="inner_foot_share area_overflow">
          <div class="area_right">
            <div class="group_share">
              <button class="btn_share btn_share_kakaotalk">
                <IconSvg
                  iconName="preview_kakaotalk"
                  iconColor="#BEA9ED"
                  bgcolor="#F3F1F1"
                  :size="36"/>
              </button>
              <button class="btn_share btn_share_facebook">
                <IconSvg
                  iconName="preview_facebook"
                  iconColor="#BEA9ED"
                  bgcolor="#F3F1F1"
                  :size="36"/>
              </button>
              <button class="btn_share btn_share_twitter">
                <IconSvg
                  iconName="preview_twitter"
                  iconColor="#BEA9ED"
                  bgcolor="#F3F1F1"
                  :size="36"/>
              </button>
              <button class="btn_share btn_share_link">
                <IconSvg
                  iconName="preview_link"
                  iconColor="#BEA9ED"
                  bgcolor="#F3F1F1"
                  :size="36"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

export default {
  name:'BoardView',
  props:{
    detailData: Object,
    isThumbnail: Boolean,
    isShare: Boolean,
    isDate: Boolean,
    isFooterBtnControaler: Boolean
  },
  components:{
    IconSvg,
  },
}
</script>
<style scoped>
.view_comm{max-width:840px;margin:0 auto;padding:160px 20px 0}
.view_comm:last-child{padding-bottom:200px}

.group_share{text-align:right}

.head_view .area_img{width:100%;padding-top:52.382%;background-color:#DDD2BD;background-size:contain}
.head_view .group_share{margin-top:32px}
.head_view .tit_view{display:block;margin-top:32px;font-weight:700;font-size:28px;line-height:36px;color:#111;word-wrap:break-word;word-break:keep-all}
.head_view .date_view{display:block;margin-top:16px;font-weight:600;font-size:16px;line-height:24px;color:#666}

.body_view{margin-top:32px;word-wrap:break-word;word-break:keep-all}
.body_view ::v-deep .tit_sub{display:block;font-weight:700;font-size:24px;line-height:30px;color:#111}
.body_view ::v-deep .desc_view{font-weight:500;font-size:16px;line-height:32px;color:#111}
.body_view ::v-deep .tit_sub + .desc_view{margin-top:25px}
.body_view ::v-deep .desc_view + .tit_sub{margin-top:32px}

.tbl_view .body_view .editor_view ::v-deep td{background:none}

.foot_view .inner_foot_btn{overflow:hidden;margin-top:80px}
.foot_view .btn_view_foot{display:block;width:180px;height:56px;padding:14px 0;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#F3F1F1}
.foot_view .btn_view_prev .icon_firfin{margin:6px 12px 6px 0}
.foot_view .btn_view_next .icon_firfin{margin:6px 0 6px 12px}

.foot_view .inner_foot_share{margin-top:32px}
.group_share .btn_share .icon_firfin{border-radius:100%}
.group_share .btn_share + .btn_share{margin-left:16px}

/* 모바일 */
@media all and (max-width:1199px){
  .view_comm{padding-top:102px}
  .view_comm:last-child{padding-bottom:48px}

  .foot_view .btn_view_foot{width:148px}
}
</style>